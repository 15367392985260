.head-search[data-v-a2d035f0] {
  position: relative;
}
.head-search .head-right[data-v-a2d035f0] {
  position: absolute;
  left: 30%;
  top: 6px;
}
.head-search .head-right .el-input[data-v-a2d035f0] {
  width: 300px;
}
.head-search .head-right .el-radio-group[data-v-a2d035f0] {
  padding-left: 10px;
}
.head-search .head-right .history-dialog[data-v-a2d035f0] {
  width: 350px;
  position: absolute;
  top: 32px;
  padding: 12px;
  background: #fff;
  z-index: 999;
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
}
.head-search .head-right .history-dialog .history-content[data-v-a2d035f0] {
  line-height: 30px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
}
.head-search .head-right .history-dialog .history-content[data-v-a2d035f0]:hover {
  background: #F5F7FA;
}
.head-search .head-right .history-dialog .history-content span[data-v-a2d035f0] {
  font-size: 14px;
  color: #606266;
  padding-left: 12px;
}
.head-search .head-right .history-dialog .btns[data-v-a2d035f0] {
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
}
.tag-content[data-v-a2d035f0] {
  padding: 5px 12px;
  background: #fff;
}
.cardBigBox[data-v-a2d035f0] {
  background-color: #FFFFFF;
  height: calc(100vh - 177px);
}
.card-content[data-v-a2d035f0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 6px;
  height: calc(100% - 68px);
  overflow: auto;
}
.card-content .box-card[data-v-a2d035f0] {
  width: calc(25% - 12px);
  cursor: pointer;
  margin: 6px;
  height: 388px;
}
.card-content .box-card .box-head[data-v-a2d035f0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.card-content .box-card .box-head .head-item[data-v-a2d035f0] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 12px;
}
.card-content .box-card .img-box[data-v-a2d035f0] {
  height: 240px;
}
.card-content .box-card .img-box img[data-v-a2d035f0] {
  width: 100%;
  height: 100%;
}
.card-content .box-card .img-box .video-js[data-v-a2d035f0] {
  width: 100%;
  height: 100%;
}
.card-content .box-card p[data-v-a2d035f0] {
  font-size: 14px;
  text-align: center;
}
.el-pagination[data-v-a2d035f0] {
  text-align: right;
}
[data-v-a2d035f0] .el-input-group__prepend .el-input {
  width: 100px !important;
}
[data-v-a2d035f0] .el-input-group__prepend .el-input .el-input__inner {
  width: 100px !important;
}
[data-v-a2d035f0] .input-with-select .el-input__inner {
  width: 240px;
  margin: 0;
}
[data-v-a2d035f0] .avue-crud .el-table {
  height: calc(100vh - 230px) !important;
  max-height: calc(100vh - 230px) !important;
}